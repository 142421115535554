import { fromBase64, toBase64 } from '@lp-root/src/utils/base-64';
import { hasWindow } from '@web/utils/platform';
import QueryString from 'querystringify';

export interface PreUserAgendarPageQueryParams {
  /** state: base-64 encoded PreUserAgendarPageParams */
  p: string;
}

export interface PreUserAgendarPageParams {
  davId?: string;
  preUserId: string;
  hcpId: string;
  hcpName: string;
  token: string;
  nextAvailableAppointmentDate: Date;
  date?: string;
  time?: string;
}

export const getPreUserAgendarPageParams = (): PreUserAgendarPageParams => {
  const params: PreUserAgendarPageQueryParams = QueryString.parse(hasWindow() ? window.location.search : '');
  const state: PreUserAgendarPageParams = JSON.parse(fromBase64(params.p));
  state.nextAvailableAppointmentDate = new Date(state.nextAvailableAppointmentDate);
  return state;
};

export const buildPreUserAgendarPageQueryParams = (state: PreUserAgendarPageParams): PreUserAgendarPageQueryParams => {
  return {
    p: toBase64(JSON.stringify(state)),
  };
};

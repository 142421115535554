import { User_Insert_Input } from '@global/utils';

export const PostUserUpsertUrl = '/users';
export interface UserUpsertRequest extends User_Insert_Input {
  session_reminder?: string;
  sleep_diary_reminder?: string;
  sleep_tracker_reminder?: string | 'default';
  timezone?: number;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export const PostDeleteUserRequestUrl = '/delete-user-request';
export interface DeleteUserRequestResponse {
  message: string;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export const GetPreUserRequestUrl = '/pre-user';
export interface GetPreUserRequest {
  id: string;
}
export interface GetPreUserResponse {
  first_name?: string;
  last_name?: string;
  document?: string;
  email?: string;
  phone?: string;
  birthdate?: string;
}

import { GetPreUserRequest, GetPreUserRequestUrl, GetPreUserResponse } from '@global/utils/api/user.controller.model';
import { createPreUserDataSource } from '@lp-root/../web.data/vigilantes.datasource.factory';
import IndexLayout, { IndexLayoutState } from '@lp-root/src/components/org.layout/layout.component';
import { Query } from '@lp-root/src/data/graphql/local-graphql-types';
import { preUserInicioNavigate } from '@lp-root/src/modules/telehealth/pre-user/pre-user-navigation';
import { ScheduleFormRowWrapper } from '@lp-root/src/modules/telehealth/schedule-page/schedule-form-row-wrapper.component';
import { PreUserAgendarPageParams, getPreUserAgendarPageParams } from '@lp-root/src/modules/telehealth/schedule-page/schedule-page.utils';
import { PageProps } from '@lp-root/src/utils/local-types';
import PreUserAuthDataSource from '@web/data/pre-user-auth.datasource';
import { useGetUsingPreUserAuth } from '@web/data/use-get-with-pre-user-auth';
import { navigate } from 'gatsby';
import QueryString from 'querystringify';
import * as React from 'react';
import { useState } from 'react';

/** @deprecated 6Qxlq3Py43BbkD5G telehealth was deprecated in june 2023 - https://www.notion.so/vigilantesdosono/remo-o-teleconsultas-pt1-23098acaf8de4bd085668cbbd92bf249?pvs=4 */
const PreUserAgendarPage: React.FunctionComponent<PageProps<unknown, Query>> = (props) => {
  const [pageParams, setPageParams] = useState<PreUserAgendarPageParams>();
  React.useEffect(() => {
    try {
      const params = getPreUserAgendarPageParams();
      PreUserAuthDataSource.setToken(params.token);
      setPageParams(params);
      if (!params.preUserId || !params.hcpId) navigate(-1);
    } catch (error) {
      console.error('ERROR: agendar.tsx:23 ~ React.useEffect ~ error', error);
      navigate(-1);
    }
  }, []);
  return <IndexLayout {...props}>{pageParams && <PageAgendar {...pageParams} />}</IndexLayout>;
};

export default PreUserAgendarPage;

////////////////////////////////////////////////////////////////////////////////////////////////////

interface IPageProps extends PreUserAgendarPageParams {
  date?: string;
  time?: string;
}

/** @deprecated 6Qxlq3Py43BbkD5G telehealth was deprecated in june 2023 - https://www.notion.so/vigilantesdosono/remo-o-teleconsultas-pt1-23098acaf8de4bd085668cbbd92bf249?pvs=4 */
const PageAgendar: React.FunctionComponent<IPageProps> = (props) => {
  const { data } = useGetUsingPreUserAuth<GetPreUserRequest, GetPreUserResponse>({
    url: `${GetPreUserRequestUrl}?${QueryString.stringify({ id: props.preUserId } satisfies GetPreUserRequest)}`,
  });

  const datasource = React.useRef(createPreUserDataSource());

  const handleScheduleSuccess = () => {
    preUserInicioNavigate<{ state: IndexLayoutState }>({
      state: { flashMessage: 'Consulta agendada com sucesso!', flashType: 'success' } satisfies IndexLayoutState,
    });
  };

  return (
    <ScheduleFormRowWrapper
      datasource={datasource.current}
      userId={props.preUserId}
      hcpId={props.hcpId}
      hcpName={props.hcpName}
      nextAvailableAppointmentDate={props.nextAvailableAppointmentDate.toString()}
      userHasDavId={!!props.davId}
      onScheduleSuccess={handleScheduleSuccess}
      scheduleDate={
        props.date && props.time
          ? {
              date: new Date(props.date),
              time: props.time,
            }
          : null
      }
      signUpData={
        data
          ? {
              initialBirthdate: data.birthdate,
              initialDocument: data.document,
              initialEmail: data.email,
              initialFirstName: data.first_name,
              initialLastName: data.last_name,
              initialPhone: data.phone,
            }
          : null
      }
    />
  );
};
